import {twMerge} from 'tailwind-merge';

type WidthContainerProps = {
  children?: React.ReactNode;
  className?: string;
  id?: string;
};

export const WidthContainer = ({children, className, id}: WidthContainerProps) => {
  return (
    <div id={id} className={twMerge('width-container w-full max-w-[1400px] px-[--side-padding] mx-auto', className)}>
      {children}
    </div>
  );
};

export const TwoColumnWidthContainer = ({children, className}: WidthContainerProps) => {
  return (
    <WidthContainer
      className={twMerge(
        'grid gap-10 grid-cols-[auto_minmax(250px,28%)] pb-20 mt-10 max-[800px]:flex max-[800px]:flex-col-reverse max-[800px]:gap-[unset] max-[800px]:[&>*:nth-child(2)]:mb-10',
        className
      )}
    >
      {children}
    </WidthContainer>
  );
};

type LandingPillProps = {
  children?: React.ReactNode;
};

export const LandingPill = ({children}: LandingPillProps) => {
  return (
    <span className="text-xs bg-[rgba(9,140,148,0.1)] text-[--panelista-color] font-semibold uppercase py-2 px-3 rounded-full tracking-wide">
      {children}
    </span>
  );
};

type SectionTitleProps = {
  children?: React.ReactNode;
  className?: string;
};

export const SectionTitle = ({children, className}: SectionTitleProps) => {
  return (
    <h2
      className={twMerge(
        'font-heading font-extrabold text-5xl max-[900px]:text-4xl max-[600px]:text-3xl leading-[1.1] tracking-tight max-[500px]:text-left',
        className
      )}
    >
      {children}
    </h2>
  );
};
